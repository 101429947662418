import React from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import GoosechaseIconDark from '../../media/goosechase-icon-dark.svg';
import styles from './AccountFormLayout.module.scss';
import Flex from './Flex';
import Container from './Container';
import routes from '../../routes';
import Link from '../Link';
import Text from '../Text';

const ForK12Educators = loadable(() => import('../../media/forK12Educators.svg'));

type Props = React.PropsWithChildren<{
  isEdu?: boolean;
}>;

const AccountFormLayout = (props: Props) => {
  return (
    <div>
      <div className={styles.mainContent}>
        <Container maxWidth="lg" className={styles.navContainer}>
          <Flex>
            <Link href={routes.HOME.path} useRouter aria-label="Goosechase home">
              <GoosechaseIconDark height="32px" />
            </Link>
          </Flex>
        </Container>

        <Container className={styles.mainContentContainer}>
          <div className={styles.textAndFormWrapper}>{props.children}</div>
        </Container>
      </div>
    </div>
  );
};

export const RightFixedContent = (
  props: React.PropsWithChildren<{
    padding?: string;
    centered?: boolean;
    className?: string;
  }>
) => (
  <div
    className={classNames(styles.rightFixedContainer, props.className, {
      [styles.centered]: props.centered,
    })}
    style={{ padding: props.padding }}
  >
    <div className={`${styles.rightFixedContent} ${props.centered ? styles.alignCenter : ''}`}>{props.children}</div>
  </div>
);

const RightFixedContentHeading1 = (props: React.PropsWithChildren<{}>) => (
  <Text el="h2" variant="heading2xs" className={styles.rightContentHeading1}>
    {props.children}
  </Text>
);

const RightFixedContentHeading2 = (props: React.PropsWithChildren<{ el: 'h2' | 'h3' | 'div'; className?: string }>) => (
  <props.el className={classNames(styles.rightContentHeading2, props.className)}>{props.children}</props.el>
);

RightFixedContent.Heading1 = RightFixedContentHeading1;
RightFixedContent.Heading2 = RightFixedContentHeading2;
AccountFormLayout.RightFixedContent = RightFixedContent;

export default AccountFormLayout;
